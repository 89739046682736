import {defineStore} from "pinia"
import type {PriceFilter} from '~/types/PriceFilter'
import type {Price} from '~/types/Price'
import {useUserStore} from "~/stores/user"

interface PurchaseSetupData {
    prices: Price[],
    durations: {[key: string]: {label: string}},
    schoolSizes: {
        // Catalog
        [key: string]: {
            // School size
            [key: string]: {
                labels: {
                    // Label size
                    [key: string]: string
                }
            }
        }
    },
    catalogs: {[key: string]: {label: string}},
}

export const usePriceStore = defineStore('price', () => {

    const priceData = ref<PurchaseSetupData>({
        prices: [],
        durations: {},
        schoolSizes: {},
        catalogs: {}
    })

    async function loadCurrencyPrices(currency: string): Promise<PurchaseSetupData> {
        const {data} = await useApiFetch<PurchaseSetupData>(`/purchase/setup/${currency}`)

        if (!data.value) {
            throw new Error('Could not load purchase setup data')
        }

        priceData.value = data.value

        return data.value
    }

    async function getCurrencyPrices(currency: string) {
        return await loadCurrencyPrices(currency)
    }

    const prices = computed(() => {
        return priceData.value?.prices || []
    })

    const catalogs = computed(() => {
        return priceData.value.catalogs || {}
    })

    const schoolSizes = computed(() => {
        return priceData.value.schoolSizes || {}
    })

    const durations = computed(() => {
        return priceData.value.durations || {}
    })

    function filterPrices(filter: PriceFilter): Price[] {
        return prices.value.filter((p: Price) => {
            if (filter.catalog && p.catalog !== filter.catalog) {
                return false
            }

            if (filter.purchaseType === 'teacher' && p.schoolSize !== 'teacher') {
                return false
            }

            if (filter.purchaseType === 'school' && p.schoolSize === 'teacher') {
                return false
            }

            if (filter.schoolSize && p.schoolSize !== filter.schoolSize) {
                return false
            }

            if (filter.duration && p.duration != filter.duration) {
                return false
            }

            return true
        })
    }

    function formatPrice(v: number) {
        return new Intl.NumberFormat(useNuxtApp().$i18n.locale.value, { style: 'currency', currency: useUserStore().geoData.currency }).format(v/100)
    }

    return {
        getCurrencyPrices,

        catalogs,
        schoolSizes,
        durations,

        filterPrices,
        formatPrice,
    }
})